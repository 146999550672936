import React, {useState} from 'react';
import { Drawer } from 'antd';
import { BrowserView, MobileView} from 'react-device-detect';
import './style.css';
import 'antd/dist/reset.css';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';
import RightMenu from './RightMenu';
import { changeLanguage } from 'i18next';

function MyHeader() {
  const [open, setOpen] = useState(false);
 
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const items = [
    {
      key: '1',
      label: (
        <i onClick={() => changeLanguage('fr')}>
          Français
        </i>
      ),
    },
    {
      key: '2',
      label: (
        <i onClick={() => changeLanguage('eng')}>
          English
        </i>
      ),
    },
    {
      key: '3',
      label: (
        <i onClick={() => changeLanguage('ar')}>
العربية        </i>
      ),
    }
    
  ];
  return (
    <>
<BrowserView>
<nav className="menuBar">

        <div className="logo" >
        <img src={require('../imgs/assets/logo.png')} width="100" alt="logo" />
        </div>
        <div className="menuCon">
        <div style={{float:"right"}}>
        <Dropdown
    menu={{
      items,
    }}
  >
    <i onClick={(e) => e.preventDefault()}>
      <Space>
        <p style={{color:'black',margin:0}}>Langue</p>
        <DownOutlined style={{color:'black'}}/>
      </Space>
    </i>
  </Dropdown>
    </div>
          <div className="rightMenu">
        
          <RightMenu mode="horizontal"/>
         
          </div>
        
        </div>
      </nav>
    
</BrowserView>
<MobileView>
<nav className="menuBar">
<div style={{float:"right"}}>
          <button className='myBtn' type="button" onClick={() => changeLanguage('fr')}>
      Fr
    </button>
    <button className='myBtn' type="button" onClick={() => changeLanguage('eng')}>
      Eng
    </button>
    <button className='myBtn' type="button" onClick={() => changeLanguage('eng')}>
      Ar
    </button>
    </div>
        <div className="logoMobile" onClick={showDrawer} >
          
        <img src={require('../imgs/assets/logo.png')} width="70" alt="logo" />
        </div>
        <div className="menuCon">
          <Drawer
           title={<img src={require('../imgs/assets/logo.png')} width="70" alt="logo"/>}
            placement="left"
            closable={true}
            onClose={onClose}
            open={open}
          >
          <RightMenu mode="inline"/>
          </Drawer>
        </div>
      </nav>
      </MobileView>
    </>
  )
}

export default MyHeader