import React from "react";
import { Menu } from "antd";
import { HomeOutlined,AppstoreOutlined,ContactsOutlined,UserOutlined } from '@ant-design/icons';

import './style.css';
import 'antd/dist/reset.css';
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
function RightMenu (props) {
  const { t } = useTranslation();

  const items = [
    {
      label: <Link to={"/"}>{t('nav.menue1')}</Link>,
      key: 'home',
      icon: <HomeOutlined />,
    },
    {
      label: <Link to={"/Gallery"}>{t('nav.menue2.title')}</Link>,
      key: 'Galery',
      className:'mySub',
      icon: <AppstoreOutlined />,
      
     
        
    },
    {
      label: <Link to={"/ContactUs"}>{t('nav.menue3')}</Link>,
      key: 'ContactUs',
      icon: <ContactsOutlined />,
    },
    {
      label: t('nav.menue4'),
      key: 'AboutUs',
      icon: <UserOutlined />,
    },
 

  ]
    return (
      <Menu  mode={props.mode} items={items} />
    
    );
  }

export default RightMenu;