import { Button, Col, Row } from "antd"
import React from "react"
import { BrowserView, MobileView } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { ComposableMap, Geographies, Geography ,Marker} from "react-simple-maps"
import "./style.css"
export default function MapChart() {
  const { t } = useTranslation();

  return (
    <>
    <BrowserView>
    <Row>
        <Col span={12}>
        <p className="homeText" >
        {t('map.title')}</p>
         <Button className="MyBtnFilled" >{t('map.boutton')}</Button>

        </Col>
        <Col span={12}>
        <ComposableMap>
      <Geographies geography="/world-countries.json"
       fill="#CC8989"
       stroke="#CC8989">
        {({ geographies }) =>
          geographies.map((geo) => (
            <Geography key={geo.rsmKey} geography={geo} />
          ))
        }
      </Geographies>
      <Marker coordinates={[45.194905324585136,23.81495558759671]}>
      <circle r={6} fill="#66413B" />

      </Marker>
      <Marker coordinates={[ -6.284927107766896,31.84651824193864]}>
        <circle r={6} fill="#66413B" />
      </Marker>
      <Marker coordinates={[-1.7418960495231242,43.4624693134037]}>
        <circle r={6} fill="#66413B" />
      </Marker>
    </ComposableMap>
        </Col>
    </Row>
    </BrowserView>
    <MobileView>
    <Row>
        <p style={{fontFamily:'Brush Script',fontSize:"20px",lineHeight:'20px',fontStyle:'italic',color:"#C2BCBC"}}>
        {t('map.title')}</p>

        <ComposableMap>
      <Geographies geography="/world-countries.json"
       fill="#CC8989"
       stroke="#CC8989">
        {({ geographies }) =>
          geographies.map((geo) => (
            <Geography key={geo.rsmKey} geography={geo} />
          ))
        }
      </Geographies>
      <Marker coordinates={[45.194905324585136,23.81495558759671]}>
      <circle r={6} fill="#66413B" />

      </Marker>
      <Marker coordinates={[ -6.284927107766896,31.84651824193864]}>
        <circle r={6} fill="#66413B" />
      </Marker>
      <Marker coordinates={[-1.7418960495231242,43.4624693134037]}>
        <circle r={6} fill="#66413B" />
      </Marker>
    </ComposableMap>
    <Button className="MyBtnFilled" style={{height:50,marginLeft:'20%'}}>{t('map.boutton')}</Button>

    </Row>
    </MobileView>
    </>
  )
}