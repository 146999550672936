import 'antd/dist/reset.css';
import MyGallery from '../component/MyGallery';
import '../component/style.css';
import MyLayout from '../component/Layout';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { Button, Space } from 'antd';
function Gallery(props) {
    const { t } = useTranslation();

    let myfolder;
    let myboutton;
  console.log(Object.keys(props).length !== 0)
  if(Object.keys(props).length === 0){
     myfolder="salon"
     myboutton=t('nav.menue2.subMenue2')
  }
  else {
    myfolder=props.folder;
    myboutton=props.boutton;
  }
  console.log(myfolder+"/"+myboutton);
  const [folder,setfolder]=useState(myfolder);
  const [boutton,setbtn]=useState(myboutton);
  const buttons= [
    {
    mybtn:t('nav.menue2.subMenue1.title'),
    folder: 'fontaine',
  },
 { mybtn:t('nav.menue2.subMenue2'),
  folder: 'salon',
},
{ mybtn:t('nav.menue2.subMenue3'),
  folder: 'sol',
},
{ mybtn:t('nav.menue2.subMenue4'),
  folder: 'sallesBain',
},
{ mybtn:t('nav.menue2.subMenue5'),
  folder: 'contreMarche',
},
{ mybtn:t('nav.menue2.subMenue6'),
  folder: 'colonnes',
},
{ mybtn:t('nav.menue2.subMenue7'),
  folder: 'portes',
},

    
  ]
  const handleClick = name => {
    console.log(name)
    setbtn(name.mybtn);
    setfolder(name.folder);
  };
  return (
    <MyLayout>
    <Space wrap>
    {buttons.map(btn => (

          <Button
            key={btn.mybtn}
            id={btn.mybtn}
            onClick={() =>handleClick(btn)}
            value={btn.mybtn}
            className={boutton === btn.mybtn ? " MyBtnActive" : "MyBtn"}
          >
            {btn.mybtn}
          </Button>
        ))}

  </Space>
    <MyGallery folder={folder}/>
   </MyLayout>
  );
}

export default  Gallery;
