import { Carousel } from 'antd';
import 'antd/dist/reset.css';
import { useTranslation } from 'react-i18next';
import CarouselElementLeft from './CarouselElementLeft';
import CarouselElementRight from './CarouselElementRight';


function MyCarousel ()  {
  const { t } = useTranslation();

    return(
  <Carousel style={{background:"#66413B"}} autoplay >
    <CarouselElementLeft img="porte.jpeg" alt="Entree" text={t('carousel.elm1')} path="/Gallery/1"/>
    <CarouselElementRight img="Escalier.jpeg" alt="Contre Marche" text={t('carousel.elm2')} path="/Gallery/4"/>
    <CarouselElementLeft img="salon.jpeg" alt="Salon Marocain" text={t('carousel.elm3')}  path="/Gallery/2"/>
    <CarouselElementRight img="Bain.jpeg" alt="Salle de Bain" text={t('carousel.elm4')} path="/Gallery/7"/>
    <CarouselElementLeft img="mural.jpeg" alt="Fontaine Mural"text={t('carousel.elm5')} path="/Gallery/5" />
    <CarouselElementRight img="khessa.jpeg" alt="Fontaine de Sol" text={t('carousel.elm6')} path="/Gallery/5"/>
    <CarouselElementLeft img="tawrik.jpeg" alt="Tawrik" text={t('carousel.elm7')} path="/Gallery/5"/>
    <CarouselElementRight img="colonne.jpeg" alt="Colonne" text={t('carousel.elm8')} path="/Gallery/3"/>

  </Carousel>
 
) 
};
export default MyCarousel;