import 'antd/dist/reset.css';
import '../component/style.css';
import locateUs from "../imgs/assets/locate.svg"
import MyLayout from '../component/Layout';
import { PhoneOutlined,MailOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';

function ContactUs() {
    const { t } = useTranslation();

  return (
    <MyLayout>
<Row gutter={{ xs: 8, sm: 24, md: 24, lg: 32 }} style={{height:'100%'}}>
    <Col span={8}>
    <img alt='contactUs' src={locateUs} width='100%' style={{height:'100%'}}></img>

    </Col>
    <Col span={16}>
    <div style={{marginLeft:'auto',marginRight:'auto',marginTop:35}}>
    <p className='myPara' style={{fontSize:20}}> {t("contactUs.title")}</p>

   
    <p className='myPara'>
        <PhoneOutlined  className='icon'/> +212661350257</p>
        <p className='myPara'><MailOutlined  className='icon'/> zelligeghifahouzin@gmail.com</p>
        
 </div>

   <iframe  title="adresse" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d423495.5887229265!2d-7.378567634374945!3d33.97816540000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xda76b3ecdcc5e49%3A0xc663ff610c358b51!2sZellige%20beldi%20Khaif%20Allah!5e0!3m2!1sfr!2sma!4v1675933025531!5m2!1sfr!2sma" width="100%" height="600" style={{border:0}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
  
    </Col>
</Row>
   
   </MyLayout>
  );
}

export default ContactUs;