import 'antd/dist/reset.css';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
function MyGallery (props)  {
  let folderName=props.folder;
 

  function myimport(r) {
    let myimages = {};
    r.keys().map(item => { 
      if(item.includes(folderName) && !item.includes("assets"))
      myimages[item] = r(item); 

    });
    return myimages;
}
const myimages =  myimport(require.context("../imgs/", true));
const images = 
    Object.entries(myimages).map(image => (
    {
      original: image[1],
      thumbnail: image[1],
    }
    ))
  ;

      return (
          <>
  
              
                  
                  <ImageGallery items={images} infinite={false} thumbnailPosition='left'/>
              
          </>
      );
  
  
 
    
}
export default MyGallery;