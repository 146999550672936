import { Button, Col, Row } from "antd";
import './style.css';
import { BrowserView, MobileView} from 'react-device-detect';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const contentStyle = {
    color: '#fff',
    lineHeight: '160px',
    background: '#66413B',
    display: "block"
    
  };
function CarouselElementLeft (props)  {
  const { t } = useTranslation();
  const navigate = useNavigate();
  
    let img=props.img;
    let alt=props.alt;
    let path=props.path;
    console.log(props)
  
    return(
      <>
      <BrowserView>
      <div className="LeftCarousel" style={contentStyle}>
        <Row>
        <Col span={12}>
        <div style={{display:"block"}}> 
        <p style={{fontFamily:'Brush Script',fontSize:"50px",lineHeight:'50px',fontStyle:'italic',marginTop:'20%',color:"#C2BCBC"}}>
            {props.text}
            </p>
        <Button className="MyBtn" onClick={()=>navigate(path)}>{t('carousel.boutton')}</Button>
        </div>
        </Col>
        <Col span={12}><div style= {{  display:"block"}}><img src={require('../imgs/assets/'+img)}  width="100%"  alt={alt} /></div></Col>

        </Row>
      </div>
       
      </BrowserView>
      <MobileView>
        <div >
        <Row >
        <div style= {{  display:"block"}}><img src={require('../imgs/assets/'+img)}  width="100%"  alt={alt} /></div>

        <div style={{marginLeft:'10%',lineHeight:10}}> 
       
       <Button className="MyBtn" style={{height:50,width:300,fontSize:15}} onClick={()=>navigate(path)}>{t('carousel.boutton')}</Button>
       </div>

        </Row>
      </div>
      </MobileView>
      </>
 
) 
};
export default  CarouselElementLeft;