import './App.css';
import 'antd/dist/reset.css';
import {useEffect } from 'react';
import MyRoute from './route';
import { BrowserRouter } from 'react-router-dom';

function App() {

  useEffect(() => {
    const handleContextmenu = e => {
        e.preventDefault()
    }
    document.addEventListener('contextmenu', handleContextmenu)
    return function cleanup() {
        document.removeEventListener('contextmenu', handleContextmenu)
    }
  }, [ ])

  return (
    <BrowserRouter>
        <MyRoute/>
      </BrowserRouter>
         
  );
}

export default App;
