import 'antd/dist/reset.css';
import MyCarousel from '../component/Carousel';
import MapChart from '../component/map';
import MyLayout from '../component/Layout';
import contactIllust from "../imgs/assets/contact_us.svg"
import MessageUs from "../imgs/assets/message.svg"

import { Button, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import '../component/style.css';
import { BrowserView, MobileView } from 'react-device-detect';

function Home() {
  const {t}=useTranslation()
  const navigate=useNavigate()
  return (
    <MyLayout>
    <MyCarousel/>
    <div style={{marginTop:10}}><MapChart/></div>
    <BrowserView>
   <Row gutter={{ xs: 8, sm: 24, md: 24, lg: 32 }}>
    <Col className="gutter-row" span={12}>
    <img alt='contactUs' src={contactIllust} width="100%"></img>
    </Col>
    <Col className="gutter-row" span={12}>
      <div className="homeText" style={{color:"#CC8989"}}>{t("contactUs.title2")}</div>
      <p className='homeText2'>{t("contactUs.description")}</p>
      <Button className='MyBtnFilled' onClick={()=>navigate("/ContactUs")}>{t("contactUs.boutton")}</Button>
    </Col>
    </Row> 
  
    </BrowserView>
    <MobileView>
      <div style={{position:'relative'}}>
      <div className="homeText" style={{color:"#CC8989",fontSize:"40px"}}>{t("contactUs.title2")}</div>

    <img alt='contactUs' src={MessageUs} width='424'></img>

        <div style={{float: 'left',
    position: 'absolute',
    zIndex: '10',
    top: '191px'}}>
      <p className='homeText2' style={{textAlign:'left',width:'50%',fontSize:'13px',lineHeight:'22px',marginLeft:'10px'}}>{t("contactUs.description")}</p>

      <Button className='MyBtnFilled' style={{height:'47px', marginRight: '177px'}} onClick={()=>navigate("/ContactUs")}>{t("contactUs.boutton")}</Button>

      </div>
      </div>

    </MobileView>
   </MyLayout>
  );
}

export default Home;
