import { useTranslation } from "react-i18next";
import {Route, Routes } from "react-router-dom";
import ContactUs from "./pages/contactUs";
import Gallery from "./pages/Gallery";
import Home from "./pages/Home";

function MyRoute() {
    const { t } = useTranslation();

    return (
    <Routes>
    <Route path="/" element={<Home/>} />
    <Route path={"/Gallery"} element={<Gallery />} />
    <Route path={"/Gallery/1"} element={<Gallery folder="porte" boutton={t('nav.menue2.subMenue7')}/>} />
    <Route path={"/Gallery/2"} element={<Gallery folder="salon" boutton={t('nav.menue2.subMenue2')}/>} />
    <Route path={"/Gallery/3"} element={<Gallery folder="colonnes" boutton={t('nav.menue2.subMenue6')}/>} />
    <Route path={"/Gallery/4"} element={<Gallery folder="contreMarche" boutton={t('nav.menue2.subMenue5')}/>} />
    <Route path={"/Gallery/5"} element={<Gallery folder="fontaine" boutton={t('nav.menue2.subMenue1.title')}/>} />
    <Route path={"/Gallery/6"} element={<Gallery folder="sol" boutton={t('nav.menue2.subMenue3')}/>} />
    <Route path={"/Gallery/7"} element={<Gallery folder="sallesBain" boutton={t('nav.menue2.subMenue4')}/>} />
    <Route path="/ContactUs" element={<ContactUs/>} />

 </Routes>)
}
export default MyRoute
