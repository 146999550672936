import React from 'react';
import './style.css';
import 'antd/dist/reset.css';
import { FacebookOutlined,InstagramOutlined,WhatsAppOutlined } from '@ant-design/icons';

import { Layout, Space } from 'antd';
import MyHeader from './nav';
import { useTranslation } from 'react-i18next';

const { Header, Footer, Content } = Layout;
const headerStyle = {
    textAlign: 'center',
    color: '#66413B',
    height: 80,
    paddingInline: 50,
    lineHeight: '64px',
    backgroundColor: 'white',

  };
  const contentStyle = {
    textAlign: 'center',
    minHeight: 120,
    lineHeight: '120px',
    color: '#66413B',
 
  };
  const footerStyle = {
    textAlign: 'center',
    color: '#66413B',
    backgroundColor: 'white',

   width:"100%"  };

function MyLayout({children}) {
  const { t } = useTranslation();
 
  return (
    <Space
    direction="vertical"
    style={{
      width: '100%',
    }}
    size={[0, 48]}
  >
    <Layout>
      <Header style={headerStyle}>
        <MyHeader/>
      </Header>
      <Content style={contentStyle}> {children}</Content>
      <Footer style={footerStyle}>

        <p style={{float:'left'}}> COPYRIGHT ALL RIGHT RESERVED {new Date().getFullYear()}</p>
        <img src={require('../imgs/assets/logo.png')} width="80" alt="logo" />
        <p style={{float:'right',margin:10}}>  {t("footer.joinUs")} <span>
        <FacebookOutlined className='icon'/><InstagramOutlined className='icon'/><WhatsAppOutlined className='icon'/></span>
        </p>

      </Footer>
    </Layout>
    </Space>
  )
}

export default MyLayout
